<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">{{ tableTitle }}</h2>

    <v-card>
      <v-card-text>
        <v-toolbar rounded style="margin-bottom: 40px;" elevation="0">
          <v-toolbar-title>Filtros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="table.filtros.titulo"
            hide-details
            placeholder="Título"
            solo
            dense
          />
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loadingPesquisar" @click="pesquisar">
            Pesquisar
          </v-btn>
        </v-toolbar>

        <v-card elevation="1">
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical />
            <v-spacer />
            <v-btn color="primary" class="mb-2" outlined @click="novo">
              Nova mensagem
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :options.sync="table.options"
            :server-items-length="table.totalItems"
            :loading="loading"
            class="elevation-1"
            loading-text="Carregando..."
          >
            <template v-slot:body="{ items }" ma-5>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td>{{ item.titulo }}</td>
                  <td>{{ item.descricao }}</td>
                  <td>
                    {{
                      tipoMensagens.length > 0 &&
                      tipoMensagens[item.tipoMensagemId - 1]
                        ? tipoMensagens[item.tipoMensagemId - 1].descricao
                        : ""
                    }}
                  </td>
                  <!-- <td>{{ time2text(item.dataPublicacao) }}</td> -->
                  <td>{{ time2text(item.dataValidade) }}</td>
                  <td class="text-right">
                    <v-icon
                      class="mr-2"
                      title="Editar dados do usuário"
                      @click="editar(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      color="primary"
                      title="Excluir mensagem"
                      @click="openDialogDelete(item)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- DIALOG DELETE: Extrair componente -->
    <template v-if="dialogDelete">
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="700px">
          <v-card>
            <v-form
              ref="formDelete"
              lazy-validation
              @submit.prevent="deleteMensagem"
            >
              <v-card-title>
                <span class="headline">Excluir Mensagem</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <span class="title">
                        Tem certeza que deseja excluir esta mensagem?
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialogDelete = false">
                  Cancelar
                </v-btn>
                <v-btn color="primary" type="submit" :loading="loadingSalvar">
                  <span style="color: white;">Excluir</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <dialog-mensagem
      v-if="dialog"
      :close-dialog="closeDialog"
      :item="mensagem"
      :callback="salvar"
    />
  </v-container>
</template>

<script>
import DialogMensagem from "./dialog-mensagem.vue";
import { MensagemService, TiposService } from "../../services";
import moment from "moment";
import { Role } from "@/constants";


export default {
  components: { DialogMensagem },

  data: () => ({
    table: {
      items: [],
      filtros: {
        titulo: ""
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      headers: [
        { text: "Título", value: "titulo" },
        { text: "Mensagem", value: "descricao" },
        { text: "Tipo", value: "tipo_mensagem_id" },
        // { text: "Data publicação", value: "data_publicacao" },
        { text: "Data validade", value: "data_validade" },
        { text: "Ações", value: "", align: "right" }
      ]
    },
    form: {
      valid: false
    },
    mensagem: MensagemService.defaultMensagem(),

    dialog: false,
    dialogDelete: false,

    rules: {
      required: value => !!value || "Campo obrigatório."
    },

    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false,

    emailExcluido: "",
    tipoMensagens: []
  }),
  watch: {
    tableOptions: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  computed: {
    tableTitle() {
      return "Mensagens";
    },
    tableOptions() {
      return this.table.options;
    }
  },
  beforeMount() {
    this.loading = true;
    this.tipoMensagens = this.$store.getters["tipos/getTipoMensagens"];
    if (this.tipoMensagens && this.tipoMensagens.length == 0) {
      TiposService.getAllTipoMensagens()
        .then(response => {
          this.tipoMensagens = response.data;
          this.$store.commit("tipos/setTipoMensagens", this.tipoMensagens);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  mounted() {
    this.reload();
  },
  created(){
    if(this.isMobile){
      this.$router.push('/check-in')
    }
    
    if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
      this.$router.push('/check-in')
    }

  },
  methods: {
    reload() {
      this.paginar();
    },

    novo() {
      this.mensagem = MensagemService.defaultMensagem();
      this.dialog = true;
    },
    editar(item) {
      // if(item.dataPublicacao) {
      //   item.dataPublicacao = new Date(item.dataPublicacao);
      // }
      if (item.dataValidade) {
        item.dataValidade = new Date(item.dataValidade);
      }
      this.mensagem = { ...item };
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    openDialogDelete(item) {
      this.mensagem = { ...item };
      this.dialogDelete = true;
    },

    deleteMensagem() {
      this.loadingSalvar = true;
      MensagemService.delete(this.mensagem.id)
        .then(() => {
          this.$root.showSucesso("Mensagem excluida com sucesso.");
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
          this.dialogDelete = false;
          this.reload();
        });
    },

    salvar() {
      this.closeDialog();
      this.reload();
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    paginar() {
      this.loading = true;
      const { page, itemsPerPage } = this.table.options;

      MensagemService.pagination(
        itemsPerPage,
        page ? page - 1 : 0,
        "descricao",
        this.table.filtros
      )
        .then(response => {
          this.table.items = response.data.content;
          this.table.totalItems = response.data.totalElements;
        })
        .finally(() => {
          this.loadingPesquisar = false;
          this.loading = false;
        });
    },

    time2text(date) {
      if (date !== null) {
        date = moment(date).format("D/M/Y [às] h:mm A z");
        return date;
      }
      return "";
    }
  }
};
</script>

<style></style>
