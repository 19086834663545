<template>
  <div class="text-center">
    <v-dialog value="true" persistent width="600px">
      <template v-if="loading">
        <v-row no-gutters style="height: 90vh;" class="text-center">
          <v-col align-self="center">
            <v-progress-circular indeterminate color="primary" :size="70" />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-card>
          <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
            <v-toolbar-title>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="closeDialog">mdi-close</v-icon>
          </v-toolbar>
          <v-row no-gutters class="pa-2">
            <v-col md="12" cols="12">
              <v-card elevation="0">
                <v-form
                  ref="form"
                  v-model="form.valid"
                  lazy-validation
                  @submit.prevent="salvar"
                >
                  <v-card-text>
                    <!-- <v-row>
                      <v-col>
                        <span class="subtitle-2">Agendar mensagem</span>
                        <v-datetime-picker
                          v-model="item.dataPublicacao"
                          label="Data mensagem"
                          dateFormat="dd/MM/yyyy"
                          datetime="date"
                          clearText="limpar"
                          :disabled="readOnly"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar-outline</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock-outline</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row> -->

                    <v-row>
                      <v-col>
                        <span class="subtitle-2">Agendar validade*</span>
                        <v-datetime-picker
                          v-model="item.dataValidade"
                          label="Validade mensagem"
                          dateFormat="dd/MM/yyyy"
                          datetime="date"
                          clearText="limpar"
                          :disabled="readOnly"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar-outline</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock-outline</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Título*</span>
                        <v-text-field
                          v-model="item.titulo"
                          :rules="[rules.required]"
                          :disabled="readOnly"
                        />
                      </v-col>
                      <v-col cols="12">
                        <span class="subtitle-2">Mensagem*</span>
                        <v-textarea
                          v-model="item.descricao"
                          rows="3"
                          :rules="[rules.required]"
                          :disabled="readOnly"
                          @focus="emoji.showEmoji = false"
                        />
                      </v-col>
                      <v-col align="right" cols="12" class="pb-0">
                        <v-emoji-picker
                          :i18n="emoji.i18n"
                          @select="selectEmoji"
                          v-show="emoji.showEmoji"
                          class="emoji-position"
                        />
                        <v-btn
                          class="emoji-position"
                          icon
                          color="#000000"
                          @click="emoji.showEmoji = !emoji.showEmoji"
                        >
                          <v-icon color="#ffcc33" large>mdi-emoticon</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Tipo*</span>
                        <v-autocomplete
                          v-model="item.tipoMensagemId"
                          :items="tiposMensagem"
                          item-text="descricao"
                          item-value="id"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btnCancelar" text @click="closeDialog">
                      <span class="text-capitalize">Cancelar</span>
                    </v-btn>
                    <v-btn
                      :disabled="!form.valid || readOnly"
                      color="primary"
                      type="submit"
                      :loading="loadingSalvar"
                    >
                      <span class="text-capitalize">Salvar edição</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { VEmojiPicker } from "v-emoji-picker";
import { MensagemService } from "../../services";

export default {
  components: { VEmojiPicker },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      required: true
    },
    closeDialog: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    emoji: {
      i18n: {
        search: "Pesquisar...",
        categories: {
          Activity: "Atividades",
          Flags: "Bandeiras",
          Foods: "Comida",
          Frequently: "Frequentes",
          Objects: "Objetos",
          Nature: "Natureza",
          Peoples: "Pessoas",
          Symbols: "Símbolos",
          Places: "Locais"
        }
      },
      showEmoji: false
    },
    form: {
      valid: false
    },
    rules: {
      required: value => !!value || "Campo obrigatório."
    },
    loadingSalvar: false,
    loading: false
  }),
  computed: {
    formTitle() {
      return this.item.id ? "Alterar mensagem" : "Nova mensagem";
    },
    tiposMensagem() {
      return this.$store.getters["tipos/getTipoMensagens"];
    }
  },
  methods: {
    salvar() {
      if (this.$refs.form.validate()) {
        if (!this.validaForm()) {
          return;
        }
        this.loadingSalvar = true;
        MensagemService.salvar(this.item)
          .then(response => {
            this.item.id = response.data.id;
            this.$root.showSucesso("Operação realizada com sucesso");
            this.callback();
          })
          .catch(erro => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Existem campos obrigatórios não preenchidos");
      }
    },
    validaForm() {
      // if ((this.item.dataPublicacao !== null && this.item.dataPublicacao < new Date()) || (this.item.dataValidade !== null && this.item.dataValidade < new Date())) {
      if (
        this.item.dataValidade == null ||
        this.item.dataValidade < new Date()
      ) {
        this.$root.showErro(
          "A <span class='font-weight-black'>data/hora de validade</span> não pode ser menor que a <span class='font-weight-black'>data/hora atual.</span>"
        );
        return false;
      }
      return true;
    },
    selectEmoji(emoji) {
      this.item.descricao += emoji.data;
    }
  }
};
</script>

<style scoped>
.emoji-position {
  padding: 0;
  position: relative;
  top: -40px;
}
.emoji-dialog {
  position: relative;
  z-index: 1000;
}
</style>
